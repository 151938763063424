/**
 * GENERATED BY RPC GEN - LESS MODIFY BY HAND
 * mobileauth-service.ts  2024-12-04T10:13:49+08:00
 */
import type { R, RpcService, MethodConfig as M } from 'bt-rpc-base'

import type { AccessToken, LoginForm } from './auth-dto'

const _ = 'MobileAuth/'

export class MobileAuthService {
  constructor(readonly r: RpcService) {}
  // A/B testing 分桶，不管登录与否，都是根据cid进行分桶，默认0代表老桶，1代表新桶。上传友盟的时候做为标签上传；客户端每天获取一次
  cidBucket(m?: M): R<number> {
    return this.r.async(_ + 'cidBucket', undefined, m)
  }
  // 获取服务器时间/Unix timestamp，秒
  epoch(m?: M): R<number> {
    return this.r.async(_ + 'epoch', undefined, m)
  }
  // web清理认证cookie; app 清理refreshToken
  logout(m?: M): R<boolean> {
    return this.r.async(_ + 'logout', undefined, m)
  }

  refresh(d: string, m?: M): R<AccessToken> {
    return this.r.async(_ + 'refresh', d, m)
  }

  token(d: LoginForm, m?: M): R<AccessToken> {
    return this.r.async(_ + 'token', d, m)
  }
}
