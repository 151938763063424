/**
* GENERATED BY RPC GEN - LESS MODIFY BY HAND
* auth-dto.ts  2024-05-22T11:45:33+08:00
*/

import {
  IsInt,
  Length,
  IsEmail,
  IsFQDN,
  IsDate,
  IsArray,
  Min,
  Max,
  IsOptional,
  IsDefined,
  IsNotEmpty,
  MinLength,
  MaxLength,
  ArrayMinSize,
  ArrayMaxSize,
  IsPositive,
  IsNegative,
} from 'class-validator';

export const APP = 'auth';

export interface  Passcode {


    //// 有效期，UNIX时间戳，秒
    expiresAt: number;


    passcode: string;
}

export class  MobToken {


    // 客户端的token
    @IsNotEmpty()
    token: string;


    // 客户端返回的运营商token
    @IsNotEmpty()
    opToken: string;


    // 客户端返回的运营商，CMCC:中国移动通信, CUCC:中国联通通讯, CTCC:中国电信
    @IsNotEmpty()
    operator: string;


    // 0/null 往约APP, 1 汗滴app
    @Min(0)
    @Max(1)
    @IsOptional()
    id?: number;

	constructor(token: string,opToken: string,operator: string) {
		this.token = token;
		this.opToken = opToken;
		this.operator = operator;
	}
}

export interface  PublicKey {


    //// https://datatracker.ietf.org/doc/html/rfc7517#appendix-A.1
    jwk: RsaJwk;
}

export class  WxBind {


    // 手机号，E.164格式, 比如 +8615012345678
    /// Pattern{regexp=\+[1-9]\d{4,14}, message=phone no. not E.164 general format}
    @IsDefined()
    mobile: string;


    // OTP (One-Time PIN) Code，可以为空如果未启用
    @Length(6, 6)
    @IsDefined()
    otp: string;


    // SecurityService/ xxx check通过之后获取到的passcode
    @MinLength(1)
    @Length(0, 64)
    passcode: string;


    // 用户同意授权snsapi_userinfo，获取code；绑定的时候还是使用这个code，10分钟内有效;android 特例，直接拼接三个段（英文冒号） openid:access_token:unionid 传递
    @IsDefined()
    @Length(10, 255)
    code: string;


    // 0/null往约APP, 1 往约公众号 2 夜奢会公众号 3 汗滴app 4 汗滴公众号
    @Min(0)
    @Max(4)
    @IsOptional()
    id?: number;

	constructor(mobile: string,otp: string,passcode: string,code: string) {
		this.mobile = mobile;
		this.otp = otp;
		this.passcode = passcode;
		this.code = code;
	}
}

export class  WxLogin {


    // 用户同意授权snsapi_userinfo，获取code；绑定的时候还是使用这个code，10分钟内有效;android 特例，直接拼接三个段（英文冒号） openid:access_token:unionid 传递
    @IsDefined()
    @Length(10, 255)
    code: string;


    // 0/null往约APP, 1 往约公众号 2 夜奢会公众号 3 汗滴app 4 汗滴公众号
    @Min(0)
    @Max(4)
    @IsOptional()
    id?: number;

	constructor(code: string) {
		this.code = code;
	}
}

export interface  RsaJwk {


    kty: string;


    e: string;


    use: string;


    kid: string;


    n: string;
}

/**
 *验证码登录预检，检查通过发送验证码
 */
export class  OtpCheck {


    // 手机号，E.164格式, 比如 +8615012345678
    /// Pattern{regexp=\+[1-9]\d{4,14}, message=phone no. not E.164 general format}
    @IsDefined()
    mobile: string;

	constructor(mobile: string) {
		this.mobile = mobile;
	}
}

export interface  AccessToken {


    //// web端拿不到，会写到cookie；返回null<特例情况，h5公众号登录会设置为openId>；JWT Bearer token
    accessToken: string;


    //// web端拿不到，返回null;默认一个月，一个月内使用的话自动延期
    refreshToken: string;


    //// web端可以拿到，accessToken有效期，Unix时间戳，epochSecond
    expiresAt: number;


    //// 登录状态，0/null 普通登录； 1 新用户； 2 回归用户，超30天
    sts: number;
}

export class  LoginForm {


    // RSA加密的密码,和OTP必传一个
    @Length(50, 1024)
    @IsOptional()
    encryptPwd?: string;


    // SecurityService/ xxx check通过之后获取到的passcode
    @MinLength(1)
    @Length(0, 64)
    passcode: string;


    @MinLength(1)
    @Length(0, 50)
    // 用户名，如果是手机号，使用E.164格式, 比如 +8615012345678
    username: string;


    // OTP (One-Time PIN) Code，可以为空如果未启用
    @Length(6, 6)
    @IsOptional()
    otp?: string;

	constructor(passcode: string,username: string) {
		this.passcode = passcode;
		this.username = username;
	}
}

/**
 *登录表单验证码预检
 */
export class  AliCaptchaCheck {


    @MinLength(1)
    @Length(0, 50)
    username: string;


    // 前端传来
    @MinLength(1)
    captchaVerifyParam: string;

	constructor(username: string,captchaVerifyParam: string) {
		this.username = username;
		this.captchaVerifyParam = captchaVerifyParam;
	}
}

