import { tsApiBaseUrl } from '@/config'
import { currentDev } from '@/config/env'
import { showToast } from '@/hooks/useVant'
import { store } from '@/pinia/store'
import { MobileAuthService } from '@/tsApi/auth/mobileauth-service'
import { getUrlChannelCode, goLogin } from '@/utils'
import { ServiceConfig, RpcService, R, MethodConfig } from 'bt-rpc-base'
import { RpcClient } from 'bt-rpc-web'
import { APP as WyAuth } from './auth/auth-dto.ts'
import { APP as DiscussAuth } from './auth/auth-discuss-dto'
import { APP as Wy } from './wy/wy-dto'
import { APP as Discuss } from './discuss/discuss-dto'
import { isWhiteListInit } from '@/hooks/useRpcWhiteList'
import CryptoJSCore from 'crypto-js/core'

export type reqType = {
  url: string
  param?: string,
}

// 刷新过期时间
export const resetWyExpiresAt = async () => {
  const storeData = store()
  try {
    if (storeData.appData.secret && storeData.appData.session && ((storeData.headerInfo['c-id'] && storeData.headerInfo['c-meta']))) {
      const res = await wyAuthService.refresh(
        storeData.appData.secret + ':' + storeData.appData.session
      )
      console.log('刷新过期时间')
      console.log(res)
      if (res.code === 0) {
        localStorage.setItem('expiresAt', res.data.expiresAt.toString())
      }
    }
  } catch (e) {
  }
}

// 正在进行中的请求队列
const reqSet: Set<string> = new Set()

// 序列化参数
const serializeAndSort = (obj: object) => {
  const sortedKeys = Object.keys(obj).sort();
  const pairs = [];

  sortedKeys.forEach(key => {
    if (obj[key] instanceof Object) {
      pairs.push(`"${key}":${serializeAndSort(obj[key])}`);
    } else {
      pairs.push(`"${key}":"${obj[key]}"`);
    }
  });

  return '{' + pairs.join(',') + '}';
}

// 生成MD5加密key
const createMD5Key = (req: reqType) => {
  const params = req?.param || {}
  const payload = { ...params }
  const key = serializeAndSort(payload)
  return CryptoJSCore.MD5(req.url + key).toString()
}

// 拦截函数
const stopRepeatRequest = (client: RpcClient, req: reqType) => {
  // client - 当前请求信息
  // req - 当前请求信息

  // isWhiteListInit校验当前请求接口是否在白名单里
  const md5Key = createMD5Key(req)
  if (isWhiteListInit(req.url) && reqSet.has(md5Key)) {
    showToast('请求过于频繁，请稍后再试')
    // 调用cancel方法
    client.constructor.prototype.cancel()
    return
  }
  reqSet.add(md5Key)
}

// 清除记录
const allowRequest = (req: reqType) => {
  // req - 当前请求信息

  const md5Key = createMD5Key(req)
  if (reqSet.has(md5Key)) {
    reqSet.delete(md5Key)
  }
}

const createRpcClient = (appName = ''): RpcService => {
  const config: ServiceConfig = {
    host: tsApiBaseUrl,
    app: appName,
    withCredentials: true,
    exceptionHandler: (err: any) => {
      const storeData = store()
      console.log('exceptionHandler', err)
      if (currentDev == 3 || currentDev == 2) {
        if (err.code == 2 || err.code == 3 || err.code == 16) {
          if (err.code == 2 || err.code == 3) {
            showToast(err.message || '参数错误')
          } else if (err.code == 16) {
            // showToast(err.message || '未登录')
            storeData.isRefreshAppInputToken = false
            goLogin()
          }
        } else {
          showToast(err.message || '系统错误')
        }
      } else {
        if (err.code == 16) {
          // showToast('登录已过期')
          storeData.isRefreshAppInputToken = false
          goLogin()
        }
      }
    },
  }

  const client = RpcClient.create(config)
  
  const originAsync = client.async.bind(client)
  const asyncRequest = async <DTO>(method: string, param?: any, cfg?: MethodConfig): R<DTO> => {

    const storeData = store()

    const payload: any = {
      ...cfg,
      headers: {
        ...cfg?.headers,
        'c-id': storeData.headerInfo['c-id'],
        'c-meta': storeData.headerInfo['c-meta'],
      },
    }

    const reqInfo: reqType = {
      url: config.app + '/' + method,
      param: param
    }
    // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
    stopRepeatRequest(config, reqInfo)

    const vConsoleRef = window?.VConsole?.instance
    if (vConsoleRef && currentDev !== 1) {
      const { host, app } = config
      const item = vConsoleRef.network.add({
        url: host + app + method + '[LOG]',
        method: 'POST',
        requestType: 'fetch',
        header: {},
        status: 'Loading',
        postData: param,
        startTime: Date.now(),
      })
      const res = await originAsync(method, param, payload)
      item.endTime = Date.now()
      item.status = 200
      item.response = res
      vConsoleRef.network.update(item.id, item);
      if (res) {
        allowRequest(reqInfo)
      }
      return res
    } else {
      const res = await originAsync(method, param, payload)
      if (res) {
        allowRequest(reqInfo)
      }
      return res
    }
  }
  client.async = asyncRequest
  return client
}


export const wyAuthClient = createRpcClient(WyAuth)
export const discussAuthClient = createRpcClient(DiscussAuth)
export const wyClient = createRpcClient(Wy)
export const discussClient = createRpcClient(Discuss)
export const wyAuthService = new MobileAuthService(wyAuthClient)
export const discussAuthService = new MobileAuthService(discussAuthClient)