/**
* GENERATED BY RPC GEN - LESS MODIFY BY HAND
* discuss-dto.ts  2023-11-13T18:19:31+08:00
*/

import {
  IsInt,
  Length,
  IsEmail,
  IsFQDN,
  IsDate,
  IsArray,
  Min,
  Max,
  IsOptional,
  IsDefined,
  IsNotEmpty,
  MinLength,
  MaxLength,
  ArrayMinSize,
  ArrayMaxSize,
  IsPositive,
  IsNegative,
} from 'class-validator';

export const APP = 'discuss';

export class  Report {


    // 被举报的topicId
    @IsDefined()
    tid: number;


    // replyId,可选，传值的话即为举报回复
    @IsOptional()
    replyId?: number;


    @IsDefined()
    @Length(1, 256)
    // 举报原因
    reason: string;


    // 作者id,客户端回传
    @IsDefined()
    @Length(1, 32)
    authorId: string;


    @IsDefined()
    @Length(1, 256)
    // 原始内容,客户端回传
    cont: string;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(tid: number,reason: string,authorId: string,cont: string,rid: string) {
		this.tid = tid;
		this.reason = reason;
		this.authorId = authorId;
		this.cont = cont;
		this.rid = rid;
	}
}

export class  ReplyId {


    // 主题id
    @IsDefined()
    tid: number;


    // replyId
    @IsDefined()
    id: number;


    // 当前用户昵称，点赞需要，取消不用
    @Length(1, 20)
    @IsOptional()
    nickname?: string;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(tid: number,id: number,rid: string) {
		this.tid = tid;
		this.id = id;
		this.rid = rid;
	}
}

export class  MoreTopic {


    @IsDefined()
    @Min(5)
    @Max(20)
    size: number;


    // 当前页最后一个
    @IsOptional()
    lastKey?: number;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(size: number,rid: string) {
		this.size = size;
		this.rid = rid;
	}
}

export interface  Reply {


    //// 作者昵称
    nickname: string;


    //// 发表 or 点赞/创建时间
    cs: number;


    //// 内容
    cont: string;


    //// 点赞总数
    likeNum: number;


    //// 被回复用户 UUID 
    toUid: string;


    //// 地区code
    region: number;


    id: number;


    //// 作者id
    uid: string;


    //// 头像
    avatarUrl: string;


    //// 1 已点赞👍 0/null未赞
    liked: number;
}

export class  TopicId {


    // 主题id
    @IsDefined()
    id: number;


    // 当前用户昵称，点赞/举报需要,其他场景不需要
    @Length(1, 20)
    @IsOptional()
    nickname?: string;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(id: number,rid: string) {
		this.id = id;
		this.rid = rid;
	}
}

export class  NewTopic {


    @IsDefined()
    @Length(1, 256)
    // 内容，回复@XXX 前台自己拼装一下，昵称变更/后台不再同步更新
    cont: string;


    // 当前用户昵称，先由客户端传递过来
    @Length(1, 20)
    @IsDefined()
    nickname: string;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(cont: string,nickname: string,rid: string) {
		this.cont = cont;
		this.nickname = nickname;
		this.rid = rid;
	}
}

export class  TopicIdSize {


    // 主题id
    @IsDefined()
    id: number;


    @IsDefined()
    @Min(5)
    @Max(20)
    size: number;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(id: number,size: number,rid: string) {
		this.id = id;
		this.size = size;
		this.rid = rid;
	}
}

export interface  Topic {


    //// 房间号/视频id
    rid: string;


    //// 内容
    cont: string;


    //// 点赞总数
    likeNum: number;


    //// 回复总数
    replyNum: number;


    //// 作者昵称
    nickname: string;


    //// 发表 or 点赞/创建时间
    cs: number;


    //// 地区code
    region: number;


    //// 列表中展示两条/主题详情首页
    topN: Array<Reply>;


    id: number;


    //// 作者id
    uid: string;


    //// 头像
    avatarUrl: string;


    //// 1 已点赞👍 0/null未赞
    liked: number;
}

export class  Room {


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(rid: string) {
		this.rid = rid;
	}
}

export class  NewReply {


    // 主题id
    @IsDefined()
    tid: number;


    // 可选，被回复用户 ID 
    @IsOptional()
    toUid?: string;


    @IsDefined()
    @Length(1, 256)
    // 内容，回复@XXX 前台自己拼装一下，昵称变更/后台不再同步更新
    cont: string;


    // 当前用户昵称，先由客户端传递过来
    @Length(1, 20)
    @IsDefined()
    nickname: string;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(tid: number,cont: string,nickname: string,rid: string) {
		this.tid = tid;
		this.cont = cont;
		this.nickname = nickname;
		this.rid = rid;
	}
}

export class  MoreReply {


    // 当前页最后一个
    @IsDefined()
    lastKey: number;


    // 主题id
    @IsDefined()
    id: number;


    @IsDefined()
    @Min(5)
    @Max(20)
    size: number;


    @IsDefined()
    // 关联id，（房间号）视频id
    @Length(1, 64)
    rid: string;

	constructor(lastKey: number,id: number,size: number,rid: string) {
		this.lastKey = lastKey;
		this.id = id;
		this.size = size;
		this.rid = rid;
	}
}

