// 接口请求拦截白名单
const isWhiteListInit = (url) => {
  const apiUrlList = new Set([
    'auth/Security/otpMock', // 特定几个号码，不真实发送
    'auth/Security/otpCheck', // 临时码预检，成功发送临时吗,支持手机
    'auth/ThirdAuth/wxBind', // 公众号登录，同时绑定手机号
    'auth/ThirdAuth/wxLogin', // 公众号微信登录
    'auth/MobileAuth/token', // 手机验证码登录
    'auth/MobileAuth/refresh', // 刷新cookie
    'auth/MobileAuth/logout', // 退出登录
    'discuss/Post/delReply', // 删除回复
    'discuss/Post/delTopic', // 删除主题
    'discuss/Post/likeReply', // 点赞回复
    'discuss/Post/likeTopic', // 点赞主题
    'discuss/Post/newReply', // 发回复,成功返回id，否则报错
    'discuss/Post/newTopic', // 发布主题,成功返回id，否则报错
    'discuss/Post/unlikeReply', // 取消点赞回复
    'discuss/Post/unlikeTopic', // 取消点赞主题
    'discuss/Report/report', // 举报主题或者回复
    'wy/Communicate/exchLngLat', // 交换经纬度，1秒中调用一次即可,返回逗号分隔的经纬度 lng,lat；如果对方没开启共享，可以降低频率
    'wy/Video/like', // 点赞某个视频
    'wy/Video/unlike', // 取消点赞某个视频
  ])
  if (apiUrlList.has(url)) {
    return true
  }
}

export { isWhiteListInit }
