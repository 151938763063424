/**
* GENERATED BY RPC GEN - LESS MODIFY BY HAND
* wy-dto.ts  2024-06-26T10:44:32+08:00
*/

import {
  IsInt,
  Length,
  IsEmail,
  IsFQDN,
  IsDate,
  IsArray,
  Min,
  Max,
  IsOptional,
  IsDefined,
  IsNotEmpty,
  MinLength,
  MaxLength,
  ArrayMinSize,
  ArrayMaxSize,
  IsPositive,
  IsNegative,
} from 'class-validator';

export const APP = 'wy';

export class  MoreVideo {


    // 一页返回的视频大小，不传默认6
    @Max(20)
    @IsOptional()
    size?: number;


    // 上一页最后一个视频的id，可不传
    @IsOptional()
    lastKey?: number;


    // techId
    @IsOptional()
    techId?: string;
}

/**
 *获取可选的套餐模型
 */
export interface  MiniProduct {


    //// 套餐id
    id: number;


    //// 套餐名称
    name: string;


    //// 套餐类型，0基础套餐，1小项套餐
    type: number;


    //// 套餐单价，单位：分/每分钟，example：350分/分钟=3.5元/分钟
    unitPriceCent: number;
}

export interface  VodStatistic {


    //// 技师视频被点赞的总数
    vodLikedCnt: number;


    //// 技师视频总数
    vodCnt: number;
}

/**
 *用户基础信息
 */
export interface  UserBasic {


    //// id
    id: string;


    //// 头像地址
    avatarUrl: string;


    //// 性别 男=0，女=1
    sex: number;


    //// 昵称/姓名
    nickName: string;
}

export class  TopQuery {


    // 上一个id,翻页用,可以为空
    @IsOptional()
    lastKey?: number;


    // 页面大小，默认5
    @Min(1)
    @Max(100)
    @IsOptional()
    size?: number;
}

export class  QueryMyRpInfo {


    // 用户id
    @IsOptional()
    userId?: string;
}

/**
 *用户会员卡信息
 */
export interface  VipInfo {


    //// 会员卡状态，0 未开通 1 已开通 2 已过期
    sts: number;


    //// 用户会员的到期时间
    expAt: number;


    //// 会员用户累计节省金额,单位分
    savedCent: number;
}

export interface  MyGrowth {


    curr: Growth;


    //// 下一个等级名字，可能为null，到达最高等级
    nextName: string;


    //// 成长值
    value: number;


    //// 过期日期 yyyy-MM-dd 格式
    expAt: string;
}

/**
 *用户统计信息
 */
export interface  StatInfo {


    //// 关注技师数/follow
    folTechNum: number;


    //// 赞过的短视频
    likeVideoNum: number;


    //// 优惠券数量(定制券+通用券)
    couponNum: number;


    //// 临期优惠券数量(定制券+通用券)/3天内到期
    beEndCpNum: number;


    //// 钱包余额，单位分
    remainAmt: number;


    //// 积分，用户积分,可抵扣套餐费用
    point: number;
}

export interface  OrderCategoryCount {


    //// 待付款订单的数量
    unPaid: number;


    //// 进行中订单的数量
    progressing: number;


    //// 待评价订单的数量
    unCommented: number;


    //// 退款/售后订单的数量
    refunding: number;
}

export interface  Feed {


    //// 视频信息
    video: Video;


    //// 作者基本信息
    author: MiniUser;


    //// 召回桶
    recallBucket: number;


    //// 排序桶
    rankBucket: number;


    //// 策略桶
    strategyBucket: number;
}

export class  BannerQuery {


    // banner类型,具体位置
    @IsDefined()
    type: BannerTypeEnum;


    // 城市编码
    @IsNotEmpty()
    cityCode: string;

	constructor(type: BannerTypeEnum,cityCode: string) {
		this.type = type;
		this.cityCode = cityCode;
	}
}

export class  DiamondAreqReq {


    @IsDefined()
    // 应用类型，0-往约，1-汗滴
    appType: number;


    @MinLength(1)
    // 城市编码
    cityCode: string;

	constructor(appType: number,cityCode: string) {
		this.appType = appType;
		this.cityCode = cityCode;
	}
}

/**
 *播放事件,上下滑动/第一次完播的时候上传即可
 */
export class  PlayAction {


    // 视频id
    @IsDefined()
    id: number;


    // 观看秒；缩写为p
    @IsDefined()
    @Min(0)
    @Max(300)
    p: number;


    // 视频时长秒；上报缩写为d
    @IsDefined()
    @Min(1)
    @Max(300)
    d: number;


    // 桶
    @IsOptional()
    recallBucket?: number;


    // 桶
    @IsOptional()
    rankBucket?: number;


    // 桶
    @IsOptional()
    strategyBucket?: number;

	constructor(id: number,p: number,d: number) {
		this.id = id;
		this.p = p;
		this.d = d;
	}
}

export class  TechShowQuery {


    // 技师id
    @MinLength(1)
    techId: string;


    // 查询作品条数，默认5条
    @Min(1)
    @Max(20)
    @IsOptional()
    size?: number;

	constructor(techId: string) {
		this.techId = techId;
	}
}

export const enum BannerTypeEnum {
	INDEX_NARROW = "INDEX_NARROW",
	INDEX_WIDE = "INDEX_WIDE",
	MINE = "MINE",
	VIP = "VIP",
	HANDI_INDEX = "HANDI_INDEX",
	HANDI_MINE = "HANDI_MINE",
	HANDI_NARROW = "HANDI_NARROW"
}

export class  UserLoc {


    // 对方id/userId
    @IsNotEmpty()
    @Length(0, 64)
    id: string;


    // 英文逗号分隔，自己的经纬度，
    @IsNotEmpty()
    @Length(0, 64)
    lngLat: string;

	constructor(id: string,lngLat: string) {
		this.id = id;
		this.lngLat = lngLat;
	}
}

export interface  IncreaseTaskRst {


    //// 完成任务是否成功
    increaseRst: boolean;


    //// 剩余红包雨次数
    availableLotteryNum: number;
}

export interface  MiniUser {


    //// 0/null普通用户；1 在线按摩技师；2下线按摩技师；
    sts: number;


    //// 所属城市，跟着city_code来，可能为null
    city: string;


    //// id
    id: string;


    //// 头像地址
    avatarUrl: string;


    //// 性别 男=0，女=1
    sex: number;


    //// 昵称/姓名
    nickName: string;
}

export interface  UserAvatar {


    //// id
    id: string;


    //// 头像地址
    avatarUrl: string;
}

export interface  OrderLabel {


    //// label类型
    labelType: number;


    //// label区订单基础信息
    order: OrderInfo;


    //// 能否加钟
    canAddClock: boolean;
}

/**
 *滑动分页用，类似TopQuery
 */
export class  SlidePage {


    // 上一个页最后一条的id,翻页用,可以为null
    @Length(1, 30)
    @IsOptional()
    lastKey?: string;


    // 页面大小，不传默认6
    @Min(1)
    @Max(100)
    @IsOptional()
    size?: number;
}

export interface  Video {


    //// 视频id
    id: number;


    //// 描述
    description: string;


    //// 视频标题
    title: string;


    //// 封面地址
    coverURL: string;


    //// 标签，英文逗号分隔，单个不超过10个字符，总长不超过80
    tags: string;


    //// 创建时间
    cs: number;


    //// 点赞数
    likeNum: number;


    //// 评论数
    replyNum: number;


    //// 频时长。单位：秒
    duration: number;


    //// 视频流
    playStreamList: Array<PlayStream>;
}

export interface  DiamondArea {


    //// 主图标集合
    primary: Array<Diamond>;


    //// 次图标集合(空集合则不展示)
    secondary: Array<Diamond>;
}

export interface  RpTask {


    //// 任务类型
    interactType: number;


    //// 任务需完成总次数
    totalNum: number;


    //// 完成任务可获得的红包雨次数
    lotteryNum: number;


    //// 任务标题
    title: string;


    //// 任务描述
    desc: string;


    //// 领取状态 0-未完成、1-可领取、2-已领取
    sts: number;


    //// 已完成的任务数量
    doneNum: number;
}

export interface  TechStamp {


    //// 可null/0 未登录/没有优惠券； 1 未领取；2 已领取
    ticketStat: number;
}

export interface  VodUserInfo {


    //// 年龄，根据技师配置展示精确值/xx后
    age: string;


    //// 简介
    desc: string;


    //// 粉丝数（被关注数，和技师详情共用逻辑）
    fans: number;


    //// id
    id: string;


    //// 头像地址
    avatarUrl: string;


    //// 性别 男=0，女=1
    sex: number;


    //// 昵称/姓名
    nickName: string;
}

export interface  MyRpInfo {


    //// 技师获取的红包总额 单位 分
    totalAmtCent: number;
}

export interface  TechServiceTime {


    //// 返回日期 yyyy-MM-dd，例2024-05-07
    day: string;


    //// 用于显示今日，明天，后天，例今天（周日）
    dayStr: string;


    //// 时间段列表，可用时间段
    avlSpans: Array<number>;


    //// 时间段列表，约满时间段
    fullSpans: Array<number>;


    //// 当天开始时间下标，默认0
    startIndex: number;
}

export interface  PlayStream {


    //// 视频流大小.单位：Byte
    size: number;


    width: number;


    height: number;


    //// 视频流码率。单位：Kbps。
    bitrate: string;


    //// LD/SD/HD/OD  , 对应标清/高清/超清/原画
    definition: string;


    //// m3u8/mp4完整地址，客户端播放使用 https://v.x/x.m3u8?auth_key=key&end=50&MtsHlsUriToken=mytokenhls
    playURL: string;


    //// mp4/m3u8/mp3
    format: string;
}

/**
 *用户成长等级信息
 */
export interface  Growth {


    //// 等级code
    code: number;


    //// 等级名称
    name: string;


    //// 等级图标
    iconUrl: string;


    //// 背景图
    bgUrl: string;


    //// 成长区间结束值
    end: number;
}

/**
 *套餐查询类
 */
export class  ProductQuery {


    // 城市编码
    @MinLength(1)
    cityCode: string;


    // 技师类型，2往约，8汗滴
    @IsDefined()
    techType: number;

	constructor(cityCode: string,techType: number) {
		this.cityCode = cityCode;
		this.techType = techType;
	}
}

export interface  RpRecord {


    //// 红包记录id
    id: number;


    //// 红包领取用户昵称
    userName: string;


    //// 红包金额 单位：分
    amtCent: number;
}

/**
 *用户详细对象
 */
export interface  UserInfo {


    //// 手机号,E.164格式，+8613312345678
    mobile: string;


    //// 出生年月 YYYY-MM-DD
    birthday: string;


    //// 微信绑定名字,可为空，未绑定
    wxId: string;


    //// id
    id: string;


    //// 头像地址
    avatarUrl: string;


    //// 性别 男=0，女=1
    sex: number;


    //// 昵称/姓名
    nickName: string;
}

export interface  TechTrainingCert {


    //// 主键
    id: number;


    //// 技师姓名
    techName: string;


    //// 参加培训时间
    trainDate: number;


    //// 证书类型
    certType: number;


    //// 证书编号
    certNumber: string;


    //// 脱敏身份证
    dstIdcard: string;


    //// 发证时间
    cs: number;
}

export interface  VodAccount {


    //// 视频号主页基础信息
    vodUserInfo: VodUserInfo;


    //// 视频号主页短视频相关统计信息
    vodStatistic: VodStatistic;
}

export interface  MiniTech {


    //// id
    id: string;


    //// 头像地址
    avatarUrl: string;


    //// 名字
    name: string;


    //// 可预约时刻/半小时，【 1-47 0:30-23:00 】
    avlMoment: number;
}

export interface  Banner {


    //// banner名字
    name: string;


    //// 图片URL
    imgUrl: string;


    //// 链接[app:product 全部套餐；app:productCode:XXX 跳转指定code套餐详情 ;app:tech?defaultQuery={"userType":"XX"} 附带参数的技师（APP通过解析对象获取userType跳转汗滴/往约技师）；app:discounts 天天优惠；app:vip 会员中心；h5:/XXX 具体路径path（以/开头）；http(s):开头的直接按所给链接跳转 ]
    link: string;
}

/**
 *偏好设置
 */
export class  FeedPref {


    // 已看过的视频id，最近30个，第一期比较简单，先通过视频id过滤
    @IsOptional()
    vids?: Array<number>;
}

export interface  Diamond {


    //// 图标名字
    name: string;


    //// 主图标URL，不做动画效果，背景透明
    iconUrl: string;


    //// 角标文字。null/空字符串 不展示。英文逗号分割表示多个角标，类似跑马灯分别展示
    badge: string;


    //// 角标动画类型，客户端内置。null/0 无动画 ；1:角标放大缩小；2:角标角度晃动并放大缩小；3:出现-翻转-消失；4:仅晃动
    animation: number;


    //// 链接[app:product 全部套餐；app:productCode:XXX 跳转指定code套餐详情 ;app:tech?defaultQuery={"userType":"XX"} 附带参数的技师（APP通过解析对象获取userType跳转汗滴/往约技师）；app:discounts 天天优惠；app:vip 会员中心；h5:/XXX 具体路径path（以/开头）；http(s):开头的直接按所给链接跳转 ]
    link: string;
}

export interface  OrderInfo {


    //// 订单id
    id: number;


    //// 待支付订单过期剩余时间
    expLeftTimeSec: number;


    //// 订单结束剩余时间
    remainingTimeSec: number;


    //// 技师头像
    avatarUrl: string;


    //// 产品略缩图
    productPicUrl: string;
}

export interface  MyRpRecord {


    //// 红包金额 单位：分
    amtCent: number;


    //// 创建时间
    cs: number;


    //// 中奖类型[0-普通红包,1=随机红包,2-用户下单10元红包,3-技师接单5元红包,4-额外大额红包]
    winType: number;
}

export class  IncreaseTaskReq {


    // 红包雨任务类型
    @IsDefined()
    interactType: number;


    // orderId[interactType-1002 用户下单完成服务时,必须传递;interactType-2002 技师接单完成服务时必须传递;interactType-2003 订单超15字好评必须传递]
    @IsOptional()
    orderId?: number;

	constructor(interactType: number) {
		this.interactType = interactType;
	}
}

/**
 *用户请愿条
 */
export interface  Petition {


    //// 可null/0,表示可以请愿；当天总请愿人数
    cnt: number;


    //// 可null/空,表示可以请愿；前几名用户，显示头像
    topN: Array<UserAvatar>;
}

export interface  RpGrabRst {


    //// 抢红包结果 true=成功 false=失败
    grabRst: boolean;


    //// 获取红包金额 单位：分
    grabAmtCent: number;


    //// 剩余可用红包雨次数
    availableLotteryNum: number;
}

