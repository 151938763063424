/**
* GENERATED BY RPC GEN - LESS MODIFY BY HAND
* auth-dto.ts  2023-09-12T10:42:05+08:00
*/

export const APP = 'discuss';

export interface  AccessToken {

    //// web端可以拿到，accessToken有效期，Unix时间戳，epochSecond
    expiresAt: number;
}

